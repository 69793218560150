<template>
  <PrimaryMenu />

  <Header title="Járműveink" />

  <SectionCars />

  <SectionContact />

  <Footer />
</template>

<script>
import PrimaryMenu from "@/components/layout/PrimaryMenu";
import Header from "@/components/layout/Header";
import SectionCars from "@/components/layout/sections/SectionCars";
import SectionContact from "@/components/layout/sections/SectionContact";
import Footer from "@/components/layout/Footer";

export default {
  name: "Cars",
  title: "Járműveink",
  components: {
    PrimaryMenu,
    Header,
    SectionCars,
    SectionContact,
    Footer,
  },
};
</script>

<style scoped lang="scss"></style>
